@import "variables";
@import "~swiper/dist/css/swiper";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~aos/dist/aos";

body {
	font-family: 'Adobe Caslon Pro';
	background: #fff;
	position: relative;
	overflow-x: hidden;
}

header {
	top: 0;
	left: 0;
	height: 70px;
	z-index: 100;
	background: $red;
	@include mq($from: lg) {
		background: rgba(255, 255, 255, 0.95);
	}

	.mobile-logo,
	.logo-small {
		height: 30px;
		width: auto;
	}
}

.call-us {
	&:hover {
		text-decoration: none;
		color: $red;
	}
}

.navbar-toggler {
	outline: none !important;
}

.navbar-collapse {
	@include mq($until: lg) {
		position: fixed;
		left: 0;
		top: 70px;
		z-index: 5;
		bottom: 0;
		right: 0;
		background: transparentize($red, 0.03);
		ul {
			height: 100%;
			width: 100%;
			text-align: center;

			&.nav {
				display: block;
			}

			li {
				margin: auto;
				width: 100%;
				max-width: 200px;

				a {
					display: block;
					padding: 30px 20px;
				}
			}
		}
	}
}

#main-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	align-items: center;
	display: flex;
	font-family: 'Geometria';

	//@include mq($until: lg) {
	//	transform: translateY(-150%);
	//	position: fixed;
	//	top: 70px;
	//	left: 0;
	//	right: 0;
	//	bottom: 0;
	//	background: transparentize($red, 0.03);
	//	&.open {
	//		transform: translateY(0);
	//	}
	//}

	li {
		font-size: 0.8125rem;
		text-transform: uppercase;

		a {
			color: #fff;
			@include mq($from: lg) {
				color: #000;
				padding: 10px;
			}

		}

		&:not(.separator):not(.lang-changes) {
			a {
				&.active,
				&:hover {
					background: none;
					@include mq($from: lg) {
						color: $red;
					}
					text-decoration: none;
					font-weight: bold;
				}
			}
		}

		&.gold {
			color: $gold;

			a {
				color: $gold;
			}
		}

		&.separator {
			margin: 0 10px;
			@include mq($until: lg) {
				height: 1px;
				background: #fff;
				width: 100%;

			}
			@include mq($from: lg) {

				height: 35px;
				background: $grey2;
				width: 1px;
			}
		}
	}


}

.lang-changes {
	padding-left: 10px;

	a {
		padding: 5px;
		font-size: 0.675rem;
		margin: 0 2px;
		display: inline-block;
		color: #fff;
		@include mq($from: lg) {
			color: #000;
		}

		&:hover,
		&.active {
			text-decoration: none;
			font-weight: bold;
			color: #fff;
			background: transparentize(#fff, 0.9);
			border-radius: 100%;
			@include mq($from: lg) {
				color: $red !important;
				background: transparentize($red, 0.9);
			}
		}
	}
}

section {
	position: relative;

}

#home {
	@include mq($from: lg) {
		height: 100vh;
	}
	overflow: hidden;

	#vid {
		margin-top: 70px;
		width: 100%;
		@include mq($from: lg) {
			margin-top: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -1000;
			overflow: hidden;
		}
	}

	.logo {
		position: absolute;
		top: 90px;
		left: 45px;
		width: 250px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.text {
		@include mq($from: lg) {
			height: auto;
			position: absolute;
			right: 65px;
			top: 120px;
		}
	}

	h1 {
		font-size: 2rem;
		@include mq($from: lg) {
			font-size: 3.125rem;
		}
		color: $red;
		text-align: center;
		font-weight: bold;
		margin-bottom: 45px;
	}
}

#intro {
	padding-top: 50px;
	@include mq($from: lg) {
		padding-top: 120px;
	}
	padding-bottom: 60px;

	h2 {
		@include mq($from: lg) {
			margin: 0 0 65px -130px;
		}
	}
}

p {
	font-size: 1.125rem;
}

h2 {
	font-size: 2.375rem;
	@include mq($from: lg) {
		font-size: 4.375rem;
	}
	font-weight: bold;
	line-height: 1em;
	margin: 0;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1em;

	span {
		display: inline-block;
	}
}

#styles {
	@include mq($from: lg) {
		padding-top: 60px;

	}

	h2 {
		margin-bottom: 3rem;
		@include mq($from: lg) {
			margin-bottom: 65px;
		}
	}

	.img-wrapper {
		margin: 25px 25px 0 0;
		@include mq($from: lg) {
			margin: 80px 55px 0 0;
		}
		position: relative;

		&:before {
			position: absolute;
			content: '';
			background: $red;

			left: 25px;
			top: -25px;
			@include mq($from: lg) {
				left: 55px;
				top: -80px;
			}
			width: 100%;
			height: 100%;
		}

		img {
			position: relative;
			z-index: 2;
		}
	}

	.style-item {
		//transition: all 150ms ease-in-out;
		min-height: 450px;
		text-align: center;
		background: #faf7ee no-repeat center center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px;

		p {
			transition: all 150ms ease-in-out;
			margin: 50px 0;
			color: $gold;
			font-size: 1rem;
			opacity: 0;
		}

		h3 {
			transition: all 150ms ease-in-out;
			font-size: 2.5rem;
			color: #fff;
		}

		.open-gallery-bt {
			opacity: 0;
			color: $gold;
			font-family: 'Geometria';
			font-weight: bold;
			font-size: 0.875rem;
			cursor: pointer;
			position: relative;

			&:before {
				transition: 300ms all ease-in-out;
				content: '';
				display: block;
				height: 2px;
				width: 25px;
				background: $gold;
				left: 101%;
				bottom: -8px;
				position: absolute;
			}

			&:hover {
				&:before {
					left: 0;
					right: 0;
					width: 100%;
				}

				&:after {
					opacity: 0;
				}
			}

			&:after {
				transition: 200ms all ease-in-out;
				opacity: 1;
				content: '';
				background: url('assets/images/styles/open-gallery-arrow.svg') no-repeat;
				background-size: 100%;
				width: 33px;
				height: 15px;
				display: block;
				position: absolute;
				transform: translateY(-50%);
				left: 99%;
				bottom: -22px;
			}
		}

		&:hover {
			background: #faf7ee;

			.open-gallery-bt,
			p {
				opacity: 1;
			}

			h3 {
				color: $gold;
			}
		}

		&-new-york {
			background-image: url('assets/images/styles/new-york-styles-submenu-images-cut-1280px.jpg');
			@include mq($from: lg) {
				background-image: url('assets/images/styles/new-york-styles-submenu-images-cut-1920px.jpg');
			}
		}

		&-industrial {
			background-image: url('assets/images/styles/industrial-styles-submenu-images-cut-1280px.jpg');
			@include mq($from: lg) {
				background-image: url('assets/images/styles/industrial-styles-submenu-images-cut-1920px.jpg');
			}
		}

		&-minimalist {
			background-image: url('assets/images/styles/minimalist-styles-submenu-images-cut-1280px.jpg');
			@include mq($from: lg) {
				background-image: url('assets/images/styles/minimalist-styles-submenu-images-cut-1920px.jpg');
			}
		}

		&-french {
			background-image: url('assets/images/styles/french-styles-submenu-images-cut-1280px.jpg');
			@include mq($from: lg) {
				background-image: url('assets/images/styles/french-styles-submenu-images-cut-1920px.jpg');
			}
		}

		@include mq($until: lg) {
			min-height: 50px;
			background: #faf7ee;
			padding-bottom: 0;
			h3 {
				color: $gold;
				font-size: 2rem;

				br {
					display: none;
				}
			}
			p {
				opacity: 1;
			}
			.open-gallery-bt {
				display: none;

			}
			.style-gallery-swiper {
				margin: 0 -40px;
				height: calc((100vw - 30px) * 0.6325);

				.swiper-slide {
					background: #faf7ee;

					img {
						height: 100%;
						width: auto;
					}
				}
			}
		}
	}

	.styles-swiper {

		.swiper-slide {
			height: auto;

		}

		@include mq($from: lg) {
			.swiper-button-next,
			.swiper-button-prev {
				display: none;
			}
		}
	}
}

#types {
	.types-hero {
		@include mq($from: lg) {
			min-height: 520px;
		}
		@include mq($until: lg) {
			padding: 50px 0;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		overflow: hidden;
		position: relative;

		.types-bg-wrapper {
			position: absolute;
			left: 0;
			//z-index: ;
			top: 0;
			width: 100%;
			height: 150%;
			@include mq($until: lg) {
				height: 100%;
			}
		}

		.types-bg {
			height: 100%;
			width: 100%;
			background: url('assets/images/types/types-image-1280px.jpg') no-repeat center center;
			background-size: cover;
			@include mq($from: lg) {
				background-image: url('assets/images/types/types-image-1920px.jpg');
			}
		}


		p {
			font-size: 1.125rem;
		}


	}

	.types-tabs {
		display: flex;
		text-align: center;
		color: $gold;

		.types-tab {
			transition: 150ms all ease-in-out;
			cursor: pointer;
			padding: 45px 10px 35px;
			flex: 1;

			.name {
				font-size: 2.2rem;
			}

			.area {
				font-size: 1.565rem;
			}

			&.active,
			&:hover {
				background: #faf7ee;
				color: $red;
			}
		}
	}


	.types-swiper {
		.swiper-slide {
			.type-desc {
				text-align: center;
				padding: 2.5rem 0;
				font-weight: bold;
				font-size: 1.565rem;
				color: $red;
			}

			&:nth-child(2) {
				position: relative;

				@include mq($from: lg) {
					&:after {
						width: 192px;
						height: 192px;
						top: 50%;
						left: 16%;
						bottom: auto;
						position: absolute;
						content: '';
						background: url('assets/images/types/dbpb.png');
						background-size: contain;
					}
				}
			}
		}

		@include mq($from: lg) {
			.swiper-button-next {
				right: 110px;
			}

			.swiper-button-prev {
				left: 110px;
			}
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	width: 10px;
	height: 30px;
	top: 65px;

	background: url('assets/images/types/arrow.svg') no-repeat center center;

	@include mq($from: lg) {
		top: 50%;
		width: 25px;
		height: 55px;
		background: url('assets/images/types/arrow.svg') no-repeat center center;
	}
}

.types-swiper {

	.swiper-button-prev,
	.swiper-button-next {
		@include mq($until: lg) {
			background: url('assets/images/types/arrow-red.svg') no-repeat center center;
		}
	}
}

.swiper-button-prev {
	transform: rotate(180deg);
}

.swiper-button-next {
}

#location {
	.location-hero {
		overflow: hidden;
		@include mq($until: lg) {
			padding: 50px 0;
		}
		@include mq($from: lg) {
			min-height: 560px;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		position: relative;


		.types-bg-wrapper {
			position: absolute;
			left: 0;
			//z-index: ;
			top: 0;
			width: 100%;
			height: 150%;
			@include mq($until: lg) {
				height: 100%;
			}
		}

		.types-bg {
			height: 100%;
			width: 100%;
			background: url('assets/images/location/panorama-image-1280px.jpg') no-repeat center center;
			background-size: cover;
			@include mq($from: lg) {
				background-image: url('assets/images/location/panorama-image-1920px.jpg');
			}
		}


		p {
			font-size: 1.125rem;
		}


	}

	.location-number {
		text-align: center;
		color: $gold;
		padding: 15px 0;

		.number {
			font-weight: bold;
			display: block;
			font-size: 3.5rem;
			@include mq($from: lg) {
				font-size: 4.6875rem;
			}
			line-height: 1em;
		}

		.desc {
			font-size: 1rem;
			@include mq($from: lg) {
				font-size: 1.125rem;
			}
			line-height: 1em;
		}
	}

	.location-swiper {
		margin-top: 40px;
		margin-bottom: 40px;

		.swiper-slide {
			height: auto;
			@include mq($from: lg) {
				border-right: 1px solid #d2bb93;

				&:nth-child(1) {
					border-left: 1px solid #d2bb93;
				}
			}
		}

		@include mq($from: lg) {
			margin-top: 100px;
			margin-bottom: 100px;
			.swiper-button-next,
			.swiper-button-prev {
				display: none;
			}
		}
	}

	.maps {

		position: relative;

		.shadow {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			box-shadow: inset 0px 0px 20px -7px rgba(0, 0, 0, 0.67);

		}

		.map-normal {
			.map-change-bt {
				&-icon {
					background-image: url('assets/images/location/google-map-button-en.svg');
				}

				&:hover {
					.map-change-bt-icon {
						background-image: url('assets/images/location/google-map-button-hover-en.svg');
					}
				}
			}
		}

		.map-google {
			transition: 300ms all ease-in-out;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0;

			&.active {
				z-index: 10;
				opacity: 1;
			}

			#map {
				height: 100%;
			}

			.map-change-bt {
				z-index: 15;

				&-icon {
					background-image: url('assets/images/location/amenities-map-button-en.svg');
				}

				&:hover {
					.map-change-bt-icon {
						background-image: url('assets/images/location/amenities-map-button-hover-en.svg');
					}
				}
			}
		}
	}

	.map-change-bt {
		cursor: pointer;
		position: absolute;
		right: 75px;
		top: -40px;
		width: 120px;
		height: 120px;
		z-index: 5;

		&-icon {
			transition: 150ms all ease-in-out;
			background: no-repeat center center;
			background-size: contain;
			width: 100%;
			height: 100%;
		}
	}

	.steps {
		padding-top: 100px;

		//h2 {
		//	font-size: 2rem;
		//	line-height: 1.5em;
		//
		//}

	}

	.steps-swiper {
		margin-top: 40px;
		margin-bottom: 60px;

		@include mq($from: lg) {
			margin-top: 60px;
			margin-bottom: 130px;
		}

		.swiper-slide {
			height: auto;
			@include mq($from: lg) {
				border-right: 1px solid #d2bb93;

				&:nth-child(1) {
					border-left: 1px solid #d2bb93;
				}
			}
		}

		@include mq($from: lg) {
			.swiper-button-next,
			.swiper-button-prev {
				display: none;
			}
		}

		h3 {
			@include mq($until: lg) {
				display: none;
			}
			margin: 20px 0;
			color: $gold;
			font-size: 2.185rem;
		}

		.img-wrapper {
			margin: 0 25px 25px;
		}

		ul {
			text-align: center;
			margin: 0;
			padding: 0;
			list-style: none;
		}


		@include mq($until: lg) {
			.swiper-button-prev,
			.swiper-button-next {
				top: calc(100vw / 2 - 45px);
			}
			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 0;
			}
			.location-number {
				//display: flex;
				padding-top: 25px;
				align-items: center;
				justify-content: center;

				.number {
					font-size: 3.5rem;
					//margin-right: 10px;
				}

				.desc {
					padding-left: 10px;
					font-size: 1rem;
					text-align: left;

				}
			}
			ul {
				//display: flex;
				//flex-wrap: wrap;
				list-style: disc;
				font-size: 0.9rem;
				text-align: left;
				padding-left: 15px;

				//li {
				//	font-size: 0.875rem;
				//	padding: 0 30px;
				//	display: block;
				//	margin: 0 auto;
				//	text-align: left;
				//	width: 50%;
				//}
			}

		}
	}
}

#history {
	.history-bg {
		background: #e9e8e1;
		padding-bottom: 60px;
		margin-bottom: 40px;
		@include mq($from: lg) {
			margin-bottom: 0;
			padding-bottom: 250px;
		}
	}

	h2 {
		line-height: 1.2em;
		margin: 80px 0 40px;

	}

	p {
		font-size: 1.125rem;
	}

	.history-split-image {
		@include mq($from: lg) {
			margin-top: -145px;
		}
	}

	.history-text {
		padding: 0 30px;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;

		@include mq($from: lg) {
			padding: 0 100px;
			top: 0;
			left: 50%;
		}
		text-align: center;

		p {
			margin: 0;
			line-height: 1em;
			font-size: 1rem;
			@include mq($from: lg) {
				font-size: 1.65rem;
			}
		}
	}

	.separator {
		margin: 20px auto;
		@include mq($from: lg) {
			margin: 50px auto;
		}
		width: 65px;
		height: 2px;
		background: #000;
	}

	#history-video {
		margin: 50px 0 70px;
		@include mq($from: lg) {
			margin: 75px 0 140px;
		}
		display: block;
		height: auto;
		width: 100%;
	}
}

#amenities {
	.amenities-hero {
		min-height: 560px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		overflow: hidden;
		position: relative;


		.types-bg-wrapper {
			position: absolute;
			left: 0;
			//z-index: ;
			top: 0;
			width: 100%;
			height: 140%;
		}

		.types-bg {
			height: 100%;
			width: 100%;
			background: url('assets/images/amenities/mosaic-image-1280px.jpg') no-repeat center center;
			background-size: cover;
			@include mq($from: lg) {
				background-image: url('assets/images/amenities/mosaic-image-1920px.jpg');
			}
		}

		p {
			font-size: 1.125rem;
		}


	}

	.amenities-swiper {

		.swiper-button-next,
		.swiper-button-prev {
			top: 50%;
			@include mq($from: lg) {
				display: none !important;
			}
		}

		.swiper-button-next {
			right: 0;
		}

		.swiper-button-prev {
			left: 0;
		}

	}

	.amenities-list {
		margin-top: 75px;
		margin-bottom: 70px;

		@include mq($from: lg) {
			display: block;
			column-gap: 70px;
			column-count: 2;
			margin-bottom: 145px;
		}

		.amenities-item {
			position: relative;
			display: inline-block;
			background: no-repeat center center;
			background-size: cover;

			@include mq($until: lg) {
				border-left: 20px solid #fff;
				border-right: 20px solid #fff;
				height: auto;
				> img {
					display: none;
				}
			}
			@include mq($from: lg) {
				width: auto;
				flex-shrink: initial;
				margin-bottom: 75px;
				&:first-child {
					margin-top: 275px;
				}
			}
			@include mq($until: lg) {
				img,
				p,
				.amenities-number {
					opacity: 0;
				}
				&.swiper-slide-active {
					.amenities-item-desc {
						animation: fadeIn 400ms ease-in-out 1s forwards;
					}

					img {
						animation: fadeIn 400ms ease-in-out 1.3s forwards;
					}

					p {
						animation: fadeIn 400ms ease-in-out 1.6s forwards;
					}

					.amenities-number {
						animation: fadeIn 400ms ease-in-out 1.9s forwards;
					}
				}
			}

			.amenities-item-desc {
				padding: 20px;
				transition: 300ms all ease-in-out;
				height: 100%;
				width: 100%;
				color: #fff;
				opacity: 0;
				@include mq($from: lg) {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;

				}
				@include mq($from: xl) {
					padding: 45px;
				}

				.amenities-logo {
					max-height: 110px;
					width: 100%;
					height: auto;
					@include mq($from: lg) {
						max-height: 100px;
					}
					@include mq($from: xl) {
						max-height: 130px;
					}

				}

				p {
					margin: 45px 0 30px;

					font-size: 1rem;
					@include mq($from: xl) {
						font-size: 1.125rem;
					}
				}

			}

			&:hover {
				.amenities-item-desc {
					opacity: 1;
				}
			}

			&-beauty {
				.amenities-item-desc {
					color: #000;
					background: transparentize(#f6adb9, 0.15);
				}
			}

			&-food {
				.amenities-item-desc {
					background: transparentize(#dd9a09, 0.15);
				}
			}

			&-market {
				.amenities-item-desc {
					background: transparentize(#006241, 0.15);
				}
			}

			&-offices {
				.amenities-item-desc {
					background: transparentize(#545658, 0.15);
				}
			}

			&-shopping {
				.amenities-item-desc {
					background: transparentize(#236093, 0.15);
				}
			}
		}
	}

}

.amenities-number {
	.number {
		display: block;
		font-weight: bold;
		line-height: 1em;
		text-align: center;
		font-size: 3.125rem;
	}

	.desc {
		font-weight: 600;
		font-size: 1.125rem;
	}
}

#socials {
	font-size: 1.625rem;

	.social-link {
		transition: 150ms all ease-in-out;
		text-decoration: none !important;

		svg {
			width: 32px;

			* {
				fill: #000;

			}
		}

		&:hover {
			svg {
				* {
					fill: $gold;
				}
			}
		}
	}

	.instagram-swiper {
		margin: 40px 0 90px;
	}

	#instagram-feed {
		.swiper-slide {
			position: relative;

			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}
		}

		.post {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: no-repeat center center;
			background-size: cover;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

footer {
	position: relative;
	padding-top: 90px;
	padding-bottom: 10px;
	background: #000;
	color: #fff;
	@include mq($until: lg) {
		padding-bottom: 90px;
		padding-top: 40px;
	}

	.mobile-contact {
		background: #000;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 50;
		right: 0;
		height: 70px;
		display: flex;
		padding: 0 30px;
		justify-content: space-evenly;

		a {
			padding: 10px;
			display: block;
		}

		img {
			width: 50px;
		}
	}


	.phone {
		font-size: 2.4rem;
	}

	.investor-logo {
		height: 55px;
		width: auto;
	}

	.copyrights {
		margin-top: 100px;
		font-weight: 600;
		font-size: 0.625rem;
		position: relative;
		@include mq($until: lg) {
			padding-top: 50px;
			padding-bottom: 10px;
			margin-top: 0;
			&:after {
				background: #fff;
				height: 1px;
				content: '';
				position: absolute;
				bottom: 0;
				width: 90%;
				left: 50%;

				transform: translateX(-50%);
			}
		}

		a {
			color: #fff;
			text-decoration: none;
		}
	}

	.see-details {
		text-decoration: underline;
		cursor: pointer;
	}

	input.form-control {
		font-size: 1rem;
		background: none;
		border: none;
		border-bottom: 1px solid #fff;
		color: #fff;
		padding: 13px 9px;
		outline: none !important;
		border-radius: 0;

		&:focus {
			background: none;
			border-color: $gold;
		}
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #fff !important;
		opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #fff !important;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
		color: #fff !important;
	}

	.terms {
		font-size: 0.65rem;
		display: flex;

		input {
			margin-top: 5px;
		}

		span {
			padding-left: 10px;
			flex: 1;
		}
	}

	input[type=submit] {
		transition: 150ms all ease-in-out;
		background: none;
		text-decoration: none;
		padding: 0 0 3px;
		line-height: 1em;
		font-weight: 600;
		border: none;
		border-bottom: 1px solid #fff;
		font-size: 1.375rem;
		color: #fff;

		&:hover {
			color: $gold;
			border-color: $gold;
		}
	}

	.detail-opened {
		box-shadow: inset 0px 0px 20px -7px rgba(0, 0, 0, 0.67);
		background: rgba(255, 255, 255, 0.95);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 55px;
		z-index: 20;
		color: #000;
		display: none;

		p {
			font-size: 0.675rem;
			margin: 0;
		}

		.detail-close {
			position: absolute;
			right: 20px;
			top: 10px;
			cursor: pointer;
			font-size: 2.5rem;
			line-height: 1em;
		}

	}
}

.galleries {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	display: none;
	bottom: 0;
	overflow: hidden;
	background: #fff;
	z-index: 200;

	.galleries-close {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		z-index: 6666;
		font-size: 2.5rem;
		line-height: 1em;
		padding: 10px 20px;
	}

	.gallery-pointer {
		cursor: none;
		left: -9999px;
		top: -9999px;
		position: absolute;
		z-index: 5555;


		img {
			width: 55px;

		}

		&.prev {

			img {

				transform: rotate(180deg);
				transform-origin: 50% 50%;
			}
		}
	}
}

.gallery-item {
	width: 100%;
	height: 100%;
	display: flex;
	display: none;
	align-items: center;
	justify-content: center;

	.slider {

		.slick-track {
			display: flex;
			align-items: center;
		}

		.slick-slide {
			opacity: 0.15;
			transition: opacity 300ms;

			img {
				width: auto;
				max-height: 90vh;
				max-width: 30vw;
				margin: 0 2vw;
			}

			&.slick-center {
				opacity: 1;

				img {
					max-width: 80vw;
				}
			}
		}
	}

	.gallery-next {
		position: absolute;
		right: 0;
		width: 50vw;
		height: 90vh;
		top: 5vh;
		cursor: none;

	}

	.gallery-prev {
		position: absolute;
		left: 0;
		width: 50vw;
		height: 90vh;
		top: 5vh;
		cursor: none;
	}
}

.mask-animation {
	@include mq($from: lg) {
		position: relative;
		&:after {
			transition: 800ms all ease-in-out;
			background: #fff;
			content: '';
			left: 0;
			top: 0;
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 5;
		}

		&.aos-animate {
			&.mask-animation-right {
				&:after {
					left: 100%;
				}
			}
		}
	}
}

[data-aos="fade-out"] {
	opacity: 1 !important;
	transition-property: opacity;

	&.aos-animate {
		opacity: 0 !important;
	}
}

.swiper-container {
	margin: 0;
}

.container-fluid {
	@include mq($until: lg) {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@import "helpers";
