@each $dir in '', t, r, b, l, x, y {

	@each $size in 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 135 {
		.p#{$dir}-#{$size} {
			@if $dir == "" {
				padding: #{$size}px;
			}
			@if $dir == t {
				padding-top: #{$size}px;
			}
			@if $dir == b {
				padding-bottom: #{$size}px;
			}
			@if $dir == l {
				padding-left: #{$size}px;
			}
			@if $dir == r {
				padding-right: #{$size}px;
			}
			@if $dir == x {
				padding-left: #{$size}px;
				padding-right: #{$size}px;
			}
			@if $dir == y {
				padding-top: #{$size}px;
				padding-bottom: #{$size}px;
			}

		}
		@include mq($from: lg) {

			.p#{$dir}-lg-#{$size} {
				@if $dir == "" {
					padding: #{$size}px !important;
				}
				@if $dir == t {
					padding-top: #{$size}px !important;;
				}
				@if $dir == b {
					padding-bottom: #{$size}px !important;;
				}
				@if $dir == l {
					padding-left: #{$size}px !important;;
				}
				@if $dir == r {
					padding-right: #{$size}px !important;;
				}
				@if $dir == x {
					padding-left: #{$size}px !important;;
					padding-right: #{$size}px !important;;
				}
				@if $dir == y {
					padding-top: #{$size}px !important;;
					padding-bottom: #{$size}px !important;;
				}

			}
		}
		@include mq($from: xxl) {

			.p#{$dir}-xxl-#{$size} {
				@if $dir == "" {
					padding: #{$size}px !important;
				}
				@if $dir == t {
					padding-top: #{$size}px !important;;
				}
				@if $dir == b {
					padding-bottom: #{$size}px !important;;
				}
				@if $dir == l {
					padding-left: #{$size}px !important;;
				}
				@if $dir == r {
					padding-right: #{$size}px !important;;
				}
				@if $dir == x {
					padding-left: #{$size}px !important;;
					padding-right: #{$size}px !important;;
				}
				@if $dir == y {
					padding-top: #{$size}px !important;;
					padding-bottom: #{$size}px !important;;
				}

			}
		}
	}
}

.overflow-h {
	overflow: hidden;
}

@include mq($from: md) {
	.overflow-lg-h {
		overflow: hidden;
	}
	.d-lg-table-row {
		display: table-row-group !important;
	}
}

.bold {
	font-weight: bold;
}

.disable-hover,
.disable-hover * {
	pointer-events: none !important;
}
