$mq-breakpoints: (
		xs: 350px,
		sm: 568px, //mobile land
		md: 768px, //tablet
		lg: 992px, //tablet land
		xl: 1300px, //laptop
		xxl: 1440px,
		xxxl: 1800px, //desktop
	// Tweakpoints
);
$grid-gutter-width: 32px;
@import "fonts";
@import "~sass-mq/_mq";
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';


/* set the overriding variables */
$grid-breakpoints: (
		start:0,
		xs: 350px,
		sm: 568px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1440px,
		xxxl: 1800px
);
$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px,
		xxl: 1285px,
	//xxxl: 1800px
);

@import '~bootstrap/scss/bootstrap';

$red: #a41840;
$red2: #a8123e;
$gold: #b49443;
$grey: #eae8e2;
$grey2: #b5b5b5;

$hamburger-layer-width: 25px;
$hamburger-layer-color: #fff;
$hamburger-layer-height: 2px;
$hamburger-layer-border-radius: 0;
$hamburger-layer-spacing: 8px;
$hamburger-padding-x: 15px;
$hamburger-padding-y: 10px;

.color-red {
	color: $red;
}

.button {
	display: inline-block;
	line-height: 1em;

	&-red {
		min-height: 47px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 220px;
		text-align: center;
		transition: 150ms all ease-in-out;
		font-family: 'Adobe Caslon Pro';
		padding: 10px 30px;
		font-size: 1.125rem;
		background: $red;
		color: #fff;
		font-variant: small-caps;

		&:hover {
			background: $gold;
			color: #fff;
			text-decoration: none;
		}
	}
}

.gold-number {
	display: flex;
	align-items: flex-end;
	color: $gold;
	@include mq($until: lg) {
		justify-content: center;
	}

	.number {
		font-size: 3.625rem;
		line-height: 2.375rem;
		@include mq($from: lg) {
			font-size: 5.625rem;
		}
		font-weight: bold;
		margin-right: 20px;
	}

	.desc {
		font-size: 1.125rem;
		line-height: 1em;
	}
}

.f-geometria {
	font-family: 'Geometria';
}

.find-match-bt {
	color: #fff;
	font-size: 0.875rem;
	position: relative;
	font-weight: bold;
	cursor: pointer;
	@include mq($until: lg) {
		margin-right: 25px;
	}

	&:after {
		transition: 300ms all ease-in-out;
		content: '';
		display: block;
		height: 2px;
		width: 25px;
		background: #fff;
		left: 101%;
		top: 100%;
		position: absolute;
	}

	&:hover {
		color: #fff;
		text-decoration: none;

		&:after {
			left: 0;
			right: 0;
			width: 100%;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}

}
